import React, { Component } from "react";
import Plot from "react-plotly.js";
import { format } from "date-fns";

class BudgetPlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budgetstart: null,
      budgetend: null,
      budgetstartts: 0,
      budgetendts: 0,
      budgetkg: null,
      co2: null,
      co2el: null,
      co2th: null,
      loading: true,
      datamissing: false,
      chachedWidth: window.outerWidth,
    };
  }

  handleResize = (e) => {
    if (this.state.chachedWidth !== window.outerWidth) {
      window.location.reload(false);
    }
    this.setState({ chachedWidth: window.outerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize);
    this.fetchDataBudget();
    this.myinterval = setInterval(async () => {
      this.fetchDataBudget();
    }, 100000);
  }

  async fetchDataBudget() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    try {
      const response = await fetch(
        `https://smarthome.iip.kit.edu/api/api/getBudget/${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()}/`,
        requestOptions
      );
      if (response.ok) {
        const responsedata = await response.json();
        this.setState(
          {
            budgetstartts: responsedata[0].start_timestamp,
            budgetendts: responsedata[0].end_timestamp,
            budgetstart: responsedata[0].start_date,
            budgetend: responsedata[0].end_date,
            budgetkg: responsedata[0].budget,
            loading: true,
            datamissing: false,
          },
          () => {
            this.fetchDataCO2(); // Call fetchDataCO2 after state update
          }
        );
      } else if (response.status === 404) {
        this.setState({ datamissing: true });
        throw new Error("error 404");
      } else {
        this.setState({ datamissing: true });
        throw new Error("some other error: " + response.status);
      }
    } catch (error) {
      console.log(error);
    }
  }

  fetchDataCO2() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getco2consumption/${this.state.budgetstartts}/${this.state.budgetendts}/`,
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json().then((responsedata) =>
            this.setState({
              co2: responsedata["co2"],
              co2el: responsedata["co2_el"],
              co2th: responsedata["co2_th"],
              loading: false,
              datamissing: false,
            })
          );
        } else if (response.status === 404) {
          this.setState({ datamissing: true });
          return Promise.reject("error 404");
        } else {
          this.setState({ datamissing: true });
          return Promise.reject("some other error: " + response.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    clearInterval(this.myinterval);
  }

  render() {
    if (this.state.datamissing) {
      return (
        <div>
          <p>Error: Data not found</p>
        </div>
      );
    } else if (this.state.loading) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {/* <p>CO2-Budget: {this.state.budgetkg} kg</p>
          <p>{format(this.state.budgetstartts, "yyyy-MM-dd")} bis {format(this.state.budgetendts, "yyyy-MM-dd")}</p> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Plot
              data={[
                {
                  type: "bar",
                  name: "Heat",
                  x: [0],
                  y: [this.state.co2th],
                  marker: { color: "rgba(153, 0, 0, 1)" },
                },
                {
                  type: "bar",
                  name: "Electricity",
                  x: [0],
                  y: [this.state.co2el],
                  marker: { color: "rgba(255, 103, 103, 1)" },
                },
                {
                  type: "bar",
                  name: "Budget left",
                  x: [0],
                  y: [Math.max(this.state.budgetkg - this.state.co2,0)],
                  marker: { color: "rgba(153, 201, 69, 1)" },
                },
              ]}
              layout={{
                title: {
                    text: `CO2-Budget: ${this.state.budgetkg} kg<br><span style="display:inline-block; width:100%;">${format(this.state.budgetstartts, "yyyy-MM-dd")} - ${format(this.state.budgetendts, "yyyy-MM-dd")}</span>`,
                    font:{size:20, color:"black"},
                    xanchor:"center",
                    x:0.5,
                },
                xaxis: {
                  showticklabels: false,
                },
                yaxis: {
                  title: "kg CO2",
                  titlefont: {size:18, color:"black"},
                  tickfont:{size:18, color:"black"},
                  gridcolor:"black",
                },
                legend: {
                    font: {size:18, color:"black"},
                    x: 5,
                },
                autosize: true,
                plot_bgcolor: "rgba(0, 0, 0, 0)",
                paper_bgcolor: "rgba(0, 0, 0, 0)",
                barmode: "stack",
                margin: {
                    l: 100, // left margin
                    r: 100, // right margin
                    b: 50, // bottom margin
                    t: 70, // top margin
                    pad: 4, // padding
                  },
              }}
              style={{ width: "300px", height: "500px" }}
              config={{displayModeBar: false}}
              useResizeHandler={false}
            />
          </div>
        </div>
      );
    }
  }
}
  
  export default BudgetPlot;