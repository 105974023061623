import { React, useState } from "react";
import { Modal } from "rsuite";
import "./anreize.css";
import keineTipps from "../../assets/keineTipps.png";

export default function Tipps() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /* return() stellt den "Tipps"-Button, das PopUp mit den Bildern und ein Platzhalter Bild, falls es keine Tipps gibt, dar.*/

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        size={window.innerWidth < 1080 ? "" : "full"}
      >
        <img
          style={{ width: "100%" }}
          src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()}/tipps/?ts=${Date.now()}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = keineTipps;
          }}
          onClick={handleClose}
          alt=""
        />
      </Modal>
      <button id="tippsBtn" onClick={handleOpen}>
        Tipps
      </button>
    </div>
  );
}
