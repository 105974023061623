import { React, useState } from "react";
import "./anreize.css";
import keineHintergruende from "../../assets/keineHintergruende.png";
import { Modal } from "rsuite";

export default function Hintergruende() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        size={window.innerWidth < 1080 ? "" : "full"}
        dialogClassName="test"
      >
        <img
          style={{ width: "100%" }}
          src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()}/hintergrund/?ts=${Date.now()}`}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = keineHintergruende;
          }} //Wenn es gar keine Hintergründe gibt, wird ein Platzhalter Bild angezeigt
          onClick={handleClose}
          alt="no pictures"
        />
      </Modal>
      <button id="hintergrundBtn" onClick={handleOpen}>
        Background information
      </button>
    </div>
  );
}
