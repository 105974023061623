import React from 'react';
import './Livedaten.css';
import LinePlotLive from '../components/Plot/LinePlotLive';
import BarPlotLive from '../components/Plot/BarPlotLive';

//Komponent, der die Livedaten anzeigt

class Livedaten extends React.Component {

    constructor(props){
      super(props);
      this.state = {  
        spalte: 'Leistung_Gesamt',
        anzeige: 'plot'
      }
      this.handleChange = this.handleChange.bind(this);
      this.onChangeValue = this.onChangeValue.bind(this);
  }

  handleChange(e) {         // Funktion, die auf eine Auswahl in <select> reagiert und den State entsprechend setzt, welcher Anschluss des ESHL im LinePlot visualisiert werden soll.
    this.setState({
      spalte: e.target.value
    })
  }

  onChangeValue(event) {          // Funktion, die auf eine Auswahl in <form> reagiert und den state entsprechend zu plot="LinePlotLive" oder bar="BarPlotLive" setzt.
    this.setState({
      anzeige: event.target.value
    })
  }

  
  render() {
    if (this.state.anzeige === 'plot'){
    return (
        <div  className="parent">
          <form className="liveForm" onChange={this.onChangeValue}>
            <input id="btn1" type='radio' name='auswahl' value='plot' defaultChecked/><label className='labelClass' htmlFor="btn1"> Plot (in seconds live) </label>
            <input id="btn2" type='radio' name='auswahl' value='bar'/><label className='labelClass' htmlFor="btn2"> Bar chart (live) </label>
          </form>
              <select value={this.state.spalte} onChange={this.handleChange}>
                <option value='Leistung_Gesamt'>Total power</option>
                <option value='2fach_Dose_Eingang'>Socket entrance</option>
                <option value='2fach_Dose_Herd_rechts'>Socket stove</option>
                <option value='2fach_Dose_Kuechenfenster_links'>Socket kitchen window left</option>
                <option value='2fach_Dose_Kuechenfenster_rechts'>Socket kitchen window right</option>
                <option value='2fach_Dose_Tuer_Schlafen1'>Socket door left bedroom</option>
                <option value='2fach_Dose_Tuer_Schlafen2'>Socket door right bedroom</option>
                <option value='3fach_Dose_Bad'>Socket bathroom</option>
                <option value='3fach_Dose_Kueche_links'>Socket kitchen left</option>
                <option value='3fach_Dose_Kueche_rechts'>Socket kitchen right</option>
                <option value='4fach_Dose_TV_1'>Socket TV 1</option>
                <option value='4fach_Dose_TV_2'>Socket TV 2</option>
                <option value='5fach_Dose_Schlafen1_1'>Socket left bedroom 1</option>
                <option value='5fach_Dose_Schlafen1_2'>Socket left bedroom 2</option>
                <option value='5fach_Dose_Schlafen2_1'>Socket right bedroom 1</option>
                <option value='5fach_Dose_Schlafen2_2'>Socket right bedroom 2</option>
                <option value='Backofen'>Oven</option>
                <option value='Dunstabzugshaube'>Extractor hood</option>
                <option value='Gefrierschrank'>Freezer</option>
                <option value='Kaffeemaschine'>Coffee machine</option>
                <option value='Klimaanlage'>Air conditioning</option>
                <option value='Kochfeld1'>Stove 1</option>
                <option value='Kochfeld2'>Stove 2</option>
                <option value='Kochfeld3'>Stove 3</option>
                <option value='Kuehlschrank'>Refrigerator</option>
                {/* <option value='LEER1'>LEER1</option>
                <option value='LEER2'>LEER2</option>
                <option value='LEER3'>LEER3</option>
                <option value='LEER4'>LEER4</option>
                <option value='LEER5'>LEER5</option>
                <option value='LEER6'>LEER6</option> */}
                <option value='Licht_Bad'>Lights bathroom</option>
                <option value='Licht_Hauptraum'>Lights living room</option>
                <option value='Licht_Schlafen1'>Lights left bedroom</option>
                <option value='Licht_Schlafen2'>Lights right bedroom</option>
                <option value='Spuelmaschine'>Dishwasher</option>
                <option value='Trockner'>Tumble dryer</option>
                <option value='Tuer_zu_Schlafen1'>Socket living room 1</option>
                <option value='Tuer_zu_Schlafen2'>Socket living room 2</option>
                <option value='Waschmaschine'>Washing machine</option>
                <option value='PVGes'>PV</option>
              </select>
          <div>
            <LinePlotLive spalte = {this.state.spalte}/>
          </div>
        </div>
        
      )
    }
    else if (this.state.anzeige === 'bar'){
      return(
        <div className="parent">
          <form className="liveForm" onChange={this.onChangeValue}>
            <input id="btn3" type='radio' name='auswahl' value='plot'/><label className='labelClass' htmlFor="btn3"> Plot (by the second live) </label>
            <input id="btn4" type='radio' name='auswahl' value='bar' defaultChecked/><label className='labelClass' htmlFor="btn4"> Bar chart (live) </label>
          </form>
          <BarPlotLive />
        </div>
      )
    }
  }
}


export default Livedaten