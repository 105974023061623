import React from "react";
import "./anreize.css";
import Kennzahlen from "./Kennzahlen.js";
import keinAnreiz from "../../assets/keinAnreiz.png";
import { Modal } from "rsuite";
import { useState } from "react";
import { useEffect } from "react";

/* Die Obere Hälfte der Startseite. Beinhaltet die drei Kennzahlen und das Anreizbild.*/

export default function Anreize() {
  const [currentTimestamp, setCurrentTimestamp] = useState(Date.now());
  const [modalOpen, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    const interval = setInterval(() => {
      aktualisieren();
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  /* aktualisieren() setzt State auf den aktuellen Tiemstamp. */

  function aktualisieren() {
    setCurrentTimestamp(Date.now() * 1); // die Multiplikation macht das Datum zu einem timestamp
  }

  /* return() zeigt die Kennzahlen und das Anreizbild. Die div "anreizeModal" stellt das PopUp dar, wenn man auf das Bild klickt.*/
  return (
    <>
      <Kennzahlen
        style={{ flex: "auto" }}
        starttime={new Date().setHours(0, 0, 0, 0)}
        endtime={currentTimestamp}
        onClick={aktualisieren}
      />
      <img
        className="pic"
        id="picAnreize"
        src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${new Date().getDate()}-${
          new Date().getMonth() + 1
        }-${new Date().getFullYear()}/anreiz/?ts=${Date.now()}`}
        onError={(e) => {
          e.target.onError = null;
          e.target.src = keinAnreiz;
        }}
        onClick={handleOpen}
        alt="Incentives could not be found."
      />
      <Modal
        open={modalOpen}
        onClose={handleClose}
        size={window.innerWidth < 1080 ? "" : "full"}
      >
        <img
          style={{ width: "100%" }}
          src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${new Date().getDate()}-${
            new Date().getMonth() + 1
          }-${new Date().getFullYear()}/anreiz/?ts=${Date.now()}`}
          onError={(e) => {
            e.target.onError = null;
            e.target.src = keinAnreiz;
          }}
          onClick={handleClose}
          alt="Incentives could not be found."
        />
      </Modal>
    </>
  );
}
