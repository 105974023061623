import React from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/Navbar.js";
import HeutigerAnreiz from "./pages/HeutigerAnreiz.js";
import Livedaten from "./pages/Livedaten.js";
import Funktionsweise from "./pages/Funktionsweise.js";
import Kontakt from "./pages/Kontakt.js";
import Kalender from "./pages/Kalender";
import Admin from "./pages/Admin";
import CO2Budget from "./pages/CO2Budget.js";
import * as IoIcons from "react-icons/io";

// Der Hauptkomponent der App. Es geht darum, in der render-Funktion die verschiedenen Seiten der App festzulegen. Diese sind jedoch nur vorhanden,
// falls der Benutzer eingeloggt ist.

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loggedin: false,
      identity: "user",
      errorMessage: "",
      loading: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = (e) => {
    //Funktion, die vom Login-Button aufgerufen wird.
    e.preventDefault();
    this.login();
    this.checkLogInStatus();
  };

  login() {
    // Die login-Funktion fordert mit eingegebenem Benuternamen und Passwort access-tokens von der API an.
    this.setState({ loading: true, errorMessage: "" });
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: document.getElementById("user").value,
        password: document.getElementById("pass").value,
      }),
      credentials: "include",
    };
    fetch("https://smarthome.iip.kit.edu/api/token/auth", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          document.getElementById("user").value = "";
          document.getElementById("pass").value = "";
          if (response.status === 401) {
            this.setState({ errorMessage: "Falsche Anmeldedaten" });
          } else if (response.status === 500 || response.status === 404) {
            this.setState({ errorMessage: "Server nicht erreichbar" });
          } else {
            this.setState({
              errorMessage: "Es ist ein Fehler aufgetreten: " + response,
            });
          }
          return Promise.reject("error: " + response.status);
        }
      })
      .then((data) => {
        this.setState({ identity: data.identity });
        this.checkLogInStatus();
        return;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  getCookie(name) {
    // Funktion, die das vorhanden Sein eines bestimmten Cookies prüft.
    if (!document.cookie) {
      return null;
    }

    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith(name + "="));

    if (xsrfCookies.length === 0) {
      return null;
    }

    return decodeURIComponent(xsrfCookies[0].split("=")[1]);
  }

  refresh() {
    // Funktion, die refresh-tokens von der API anfordert, damit man eingeloggt bleibt
    const csrfToken = this.getCookie("csrf_refresh_token");
    const requestOptions = {
      method: "POST",
      headers: { "X-CSRF-TOKEN": csrfToken },
      credentials: "include",
    };
    fetch("https://smarthome.iip.kit.edu/api/token/refresh", requestOptions)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject("error: " + response.status);
        }
      })
      .then((data) => {
        this.setState({ identity: data.identity });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentDidMount() {
    // wenn die App aufgerufen wird, wird geprüft, ob man schon eingeloggt ist und danach werden die Cookies alle 14:40min refresht.
    this.checkLogInStatus(); // refresh() macht nichts, wenn mann nicht eingeloggt ist.
    this.refresh();
    this.myinterval = setInterval(() => {
      this.refresh();
    }, 880000);
  }

  checkLogInStatus() {
    // Funktion, die prüft ob ein access-token vorhanden ist oder nicht und den State entsprechend setzt.
    if (!document.cookie) {
      this.setState({ loggedin: false });
    }
    const xsrfCookies = document.cookie
      .split(";")
      .map((c) => c.trim())
      .filter((c) => c.startsWith("csrf_access_token="));
    if (xsrfCookies.length !== 0) {
      this.setState({ loggedin: true });
    } else {
      this.setState({ loggedin: false });
    }
  }

  render() {
    // Abhängig von this.state.loggedIn kommt man auf das Login Fenster oder auf den eigentlichen Content.
    if (this.state.loggedin !== true) {
      return (
        <>
          <Navbar />
          <form className="Login" action="" onSubmit={this.handleSubmit}>
            <input
              className="textField"
              name="username"
              type="username"
              placeholder="Benutzername"
              id="user"
            />
            <input
              className="textField"
              name="password"
              type="password"
              placeholder="Password"
              id="pass"
              style={{ margin: "2% 0" }}
            />
            {this.state.loading ? (
              <p
                className="refresh spin"
                style={{
                  width: "100%",
                  justifyContent: "center",
                  fontSize: "150%",
                  textAlign: "center",
                  marginBottom: "2%",
                  color: "black",
                }}
              >
                <IoIcons.IoMdRefresh />
              </p>
            ) : !!this.state.errorMessage ? (
              <p
                style={{
                  marginBottom: "2%",
                  textAlign: "center",
                  color: "#910f37",
                  fontSize: "150%",
                }}
              >
                {this.state.errorMessage}
              </p>
            ) : (
              <></>
            )}
            <input className="button" type="submit" value="Login" />
          </form>
        </>
      );
    } else if (this.state.identity === "admin") {
      return (
        <>
          <Router>
            <Navbar identity={this.state.identity} />
            <Switch>
              <Route path="/" exact component={HeutigerAnreiz} />
              <Route path="/livedaten" component={Livedaten} />
              <Route path="/budget" component={CO2Budget} />
              <Route path="/kalender" component={Kalender} />
              <Route path="/funktionsweise" component={Funktionsweise} />
              <Route path="/kontakt" component={Kontakt} />
              <Route path="/admin" component={Admin} />
            </Switch>
          </Router>
        </>
      );
    } else {
      return (
        <>
          <Router>
            <Navbar identity={this.state.identity} />
            <Switch>
              <Route path="/" exact component={HeutigerAnreiz} />
              <Route path="/livedaten" component={Livedaten} />
              <Route path="/kalender" component={Kalender} />
              <Route path="/funktionsweise" component={Funktionsweise} />
              <Route path="/kontakt" component={Kontakt} />
            </Switch>
          </Router>
        </>
      );
    }
  }
}

export default App;
