import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import * as TbIcons from "react-icons/tb";

export default function TrendPlot() {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    fetch("https://smarthome.iip.kit.edu/api/api/getkwhlasttwoweeks", {
      credentials: "include",
    })
      .then((response) => {
        return response.json();
      })
      .then((response) => {
        response.trendDates = response.dates.slice(
          response.estimates.findIndex((el) => el !== 0)
        );
        response.trendEstimates = response.estimates.slice(
          response.estimates.findIndex((el) => el !== 0)
        );
        setData(response);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, []);

  return (
    <>
      {loading === true ? (
        <p>Loading...</p>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            margin: "0 auto",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
            }}
          >
            <p>Trend: {(data.slope > 0 ? "+" : "") + data.slope} kWh/d</p>
            <div style={{ display: "inline-flex", alignItems: "center" }}>
              <TbIcons.TbMathAvg />
              <p>{data.mean} kWh/d</p>
            </div>
          </div>
          <Plot
            style={{ width: "100%" }}
            data={[
              {
                type: "bar",
                x: data.dates,
                y: data.kwh,
                name: "Verbrauch",
              },
              {
                type: "scatter",
                x: data.trendDates,
                y: data.trendEstimates,
                name: "Trend",
              },
            ]}
            layout={{
              yaxis: {
                title: "[kWh]",
                gridcolor: "black",
              },
              autosize: true,
              showlegend: false,
              margin: {
                r: 0,
                t: 0,
                pad: 5,
              },
              plot_bgcolor: "rgba(0, 0, 0, 0)",
              paper_bgcolor: "rgba(0, 0, 0, 0)",
              font: {
                color: "black",
              },
            }}
            useResizeHandler
          ></Plot>
        </div>
      )}
    </>
  );
}
