import React from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';


// Daten für das Auswahlmenü der Navigationsleiste

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome />,
    cName: 'nav-text'
  },
  {
    title: 'Live data',
    path: '/livedaten',
    icon: <AiIcons.AiOutlineLineChart />,
    cName: 'nav-text'
  },
  {
    title: 'CO2-Budget',
    path: 'budget',
    icon: <FaIcons.FaPoll />,
    cName: 'nav-text'
  },
  {
    title: 'Calendar',
    path: '/kalender',
    icon: <FaIcons.FaCalendarAlt />,
    cName: 'nav-text'
  },
  {
    title: 'Functionality',
    path: '/funktionsweise',
    icon: <IoIcons.IoMdHelpCircle />,
    cName: 'nav-text'
  },
  {
    title: 'Contact',
    path: '/kontakt',
    icon: <FaIcons.FaPhoneAlt />,
    cName: 'nav-text'
  },
  {
    title: 'Admin',
    path: '/admin',
    icon: <IoIcons.IoIosSettings />,
    cName: 'nav-text'
  }
];