import React from 'react';
import "./Kontakt.css";

// ElitePartner.com

function Kontakt() {
  return (
    <div className="Kontakt">
      <div>
        <h1 className = "Name">Leandra Scharnhorst</h1>
        <text className = "text">
          Hertzstraße 16 <br />
          Building 06.33, room 115 <br />
          leandra.scharnhorst@kit.edu <br />
          +49 721 608-44578 
        </text>
      </div>
      <div>
        <h1 className = "Name" id="ThorbenBesterMannPlsEinsNull">Thorben Sandmeier</h1>
        <text className = "text">
          Hertzstraße 16 <br />
          Building 06.33, room 211 <br />
          thorben.sandmeier@kit.edu <br />
          +49 721 608-44402
        </text>
      </div>
    </div>
  );
}

export default Kontakt;