import React, { Component } from "react";
import Plot from "react-plotly.js";
import "./LinePlot.css";

//

class LinePlot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsondatay: null,
      jsondatax: null,
      loading: true,
      datamissing: false,
      pvSum: null,
      chachedWidth: window.outerWidth,
      phases: [],
      plotData: [],
    };
  }

  handleResize = (e) => {
    if (this.state.chachedWidth !== window.outerWidth) {
      window.location.reload(false); //deprecated; Der Browser wird zu einem reload gezwungen, da sich der Plot ansonsten nicht immer wieder an die Größe des viewports anpasst.
    }
    this.setState({ chachedWidth: window.outerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize); // Immer wenn die Größe des viewports geändert wird, wird handleResize() aufgerufen
    this.fetchData();
    this.getPhases();
  }

  /*Wenn sich irgendetwas an den Aufrufparameter des Plots ändert und Start- und Endzeit nicht in der Zukunft liegen, werden Daten für einen neuen Plot abgerufen.*/

  componentDidUpdate(prevProps) {
    if (
      (this.props.starttime !== prevProps.starttime ||
        this.props.endtime !== prevProps.endtime ||
        this.props.spalte !== prevProps.spalte ||
        this.props.min !== prevProps.min) &&
      new Date(this.props.starttime) <= new Date() &&
      new Date(this.props.endtime) <= new Date().setHours(23, 59, 59, 999)
    ) {
      this.fetchData();
      this.getPhases();
    }
  }

  /* fetchData() fragt die Gesamtleistungsdaten des ESHL in minütlichen oder sekündlichen Intervallen ab. */

  fetchData() {
    try {
      this.setState({ loading: true });
      const requestOptions = {
        method: "GET",
        credentials: "include",
      };
      fetch(
        this.props.spalte === "PVGes"
          ? `https://smarthome.iip.kit.edu/api/api/getpvdata/${this.props.starttime}/${this.props.endtime}/min=${this.props.min}/`
          : `https://smarthome.iip.kit.edu/api/api/getdata/${this.props.spalte}/${this.props.starttime}/${this.props.endtime}/min=${this.props.min}/`,
        requestOptions
      )
        .then((response) => {
          if (response.ok) {
            this.setState({
              datamissing: false,
            });
            return response.json();
          }
        })
        .then((data) =>
          this.setState({
            jsondatay: Object.values(data.myData[this.props.spalte]), // Hier werden die Daten im State gespeichert
            jsondatax: Object.values(data.myData.Timestamp), // Hier werden die Daten im State gespeichert
            pvSum: this.props.spalte === "PVGes" ? data.kwh : null,
            loading: false,
          })
        )
        .then(() => {
          this.getPlotData();
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }

  getPhases() {
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getAnreiz/${new Date(
        this.props.starttime
      ).getDate()}-${new Date(this.props.starttime).getMonth() + 1}-${new Date(
        this.props.starttime
      ).getFullYear()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data?.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].limit !== null &&
            data[0].mode !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
              });
              number = number + 1;
            }
          }
        }
        this.setState({ phases: result });
      })
      .catch((error) => console.log(error));
  }

  /* toDate(array) wandelt ein Array von Timestamps zu Daten um. */

  toDate(array) {
    const dateArray = [];
    for (let i = 0; i < array.length; i++) {
      dateArray.push(new Date(array[i]));
    }
    return dateArray;
  }

  getPlotData() {
    try {
      const plotData = [];
      let phaseDataX = [];
      let phaseDataY = [];
      for (const phase of this.state.phases) {
        const phaseStart = this.state.jsondatax.findIndex(
          (number) => number >= phase.start
        );
        const phaseEnd = this.state.jsondatax.findIndex(
          (number) => number >= phase.end
        );
        phaseDataX = this.state.jsondatax.slice(phaseStart, phaseEnd);
        phaseDataY = this.state.jsondatay.slice(phaseStart, phaseEnd);
        plotData.push({
          type: "line",
          x: this.toDate(phaseDataX),
          y: Array.from({ length: phaseDataX.length }, (v, i) => phase.limit),
          line: {
            color: "red",
            dash: phase.mode === "max" ? "dot" : "dash",
          },
          name: phase.mode === "max" ? "Maximum limit" : "Average limit",
          legendgroup: phase.mode === "max" ? 'Maximum limit' : 'Average limit',
          showlegend:
            phase.mode === "max" ?
              plotData.filter(el => el.name === "Maximum limit").length > 0 ? false : true :
              plotData.filter(el => el.name === "Average limit").length > 0 ? false : true
        });
        if (phase.mode === "max") {
          const max = Math.max(...phaseDataY);
          plotData.push({
            type: "line",
            x: this.toDate(phaseDataX),
            y: Array.from({ length: phaseDataX.length }, (v, i) => max),
            line: {
              color: "blue",
              dash: "dot",
            },
            name: "Maximum demand",
            legendgroup: 'MMaximum demand',
            showlegend: plotData.filter(el => el.name === "Maximum demand").length > 0 ? false : true
          });
        } else if (phase.mode === "mean") {
          const mean =
            phaseDataY.reduce((prev, curr) => prev + curr, 0) /
            phaseDataY.length;
          plotData.push({
            type: "line",
            x: this.toDate(phaseDataX),
            y: Array.from({ length: phaseDataX.length }, (v, i) => mean),
            line: {
              color: "blue",
              dash: "dash",
            },
            name: "Average demand",
            legendgroup: 'Average demand',
            showlegend: plotData.filter(el => el.name === 'Average demand').length > 0 ? false : true
          });
        }
      }
      plotData.push({
        type: "line",
        x: this.toDate(this.state.jsondatax),
        y: this.state.jsondatay,
        line: {
          color: "blue",
        },
        name: "Demand",
        legendgroup: 'Verbrauch'
      });
      this.setState({
        plotData: plotData,
      });
    } catch (error) {
      console.log(error);
    }
  }

  /* average(arr) berechnet den Durchschnitt eines Arrays. */

  average = (arr) => arr.reduce((p, c) => p + c, 0) / arr.length;

  render() {
    if (new Date(this.props.starttime).setHours(0, 0, 0, 0) > new Date()) {
      // Wenn ein Tag in der Zukunft abgefragt wird
      return (
        <div className="historischeKennzahlen">
          Nobody can look into the future :)
        </div>
      );
    } else if (this.state.datamissing === true) {
      // Wenn die Anfrage an die Api schief gelaufen ist.
      return (
        <div>
          <p>Error: Data not found</p>
        </div>
      );
    } else if (this.state.loading === true) {
      // Wenn noch auf eine Antwort der API gewartet wird.
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width:
              window.innerWidth > 1081 ? "1080px" : window.innerWidth * 0.95,
            textAlign: "center",
          }}
        >
          <div className="historischeKennzahlen">
            <div>
              Maximum:{" "}
              {this.state.pvSum !== null
                ? Math.round(Math.min(...this.state.jsondatay))
                : Math.round(Math.max(...this.state.jsondatay))}{" "}
              W
            </div>
            <div>
              Average: {Math.round(this.average(this.state.jsondatay))} W
            </div>
            <div>
              {/*Ja, die Zeile ist zu lang, wenn PV ausgewählt ist wird der Betrag der summierten abgefragten Daten als "Produzierte Energie" angegeben, bei anderer Auswahl wird "Nachgefragte Energie" angegeben.*/}
              {`${
                this.state.pvSum !== null
                  ? `Energy generation: ${Math.abs(
                      Math.round(
                        (this.average(this.state.jsondatay) / 1000) *
                          ((this.props.endtime - this.props.starttime) /
                            3600000) *
                          1000
                      ) / 1000
                    )} kWh`
                  : `Energy demand: ${
                      Math.round(
                        (this.average(this.state.jsondatay) / 1000) *
                          ((this.props.endtime - this.props.starttime) /
                            3600000) *
                          1000
                      ) / 1000
                    } kWh`
              }`}
            </div>
          </div>
          <Plot
            className="passenderPlot"
            data={this.state.plotData}
            layout={{
              xaxis: {
                title: "Time",
              },
              yaxis: {
                title: "Power demand in Watt",
                rangemode: "tozero",
              },
              autosize: true,
              plot_bgcolor: "rgba(0, 0, 0, 0)",
              paper_bgcolor: "rgba(0, 0, 0, 0)",
              showlegend:
                window.innerWidth < 600 || this.state.plotData.length === 1
                  ? false
                  : true,
            }}
            useResizeHandler
          />
        </div>
      );
    }
  }
}

export default LinePlot;
