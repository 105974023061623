import React from "react";
import Calendar from "react-calendar";
import "./Tag.css";
import LinePlot from "../Plot/LinePlot";
import BarPlot from "../Plot/BarPlot";
import keinAnreiz from "../../assets/keinAnreiz.png";
import { Modal } from "rsuite";

/*Tag.js und Zeitspanne.js sind leider misslungene Komponenten. Durch Radio-Buttons werden verschiedene Anreize ausgewählt, jedoch findet dies im Code sehr umständlich 
mit if/else Anweisungen statt. Es funktioniert, uns fehlt leider die Zeit das ganze nochmal gescheit zu implementieren.*/

class Tag extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: new Date(),
      spalte: "Leistung_Gesamt",
      anzeige: "sec",
      modalOpen: false,
      anreizAuswertung: [],
      phases: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleOpen = () => this.setState({ modalOpen: true });
  handleClose = () => this.setState({ modalOpen: false });

  /* handleChange(e) reagiert auf die Auswahl eines bestimmten Anschlusses des ESHL in <select>. */

  handleChange(e) {
    this.setState({
      spalte: e.target.value,
    });
  }

  /* onChangeValue(event) reagiert auf die Auswahl der verschiedenen Anzeigen durch die oben angesprochenen Radio Buttons. */

  onChangeValue(event) {
    this.setState({
      anzeige: event.target.value,
    });
  }

  onChange = (date) => {
    this.setState({ date });
    this.getPhases(date);
  };

  componentDidMount() {
    fetch(
      "https://smarthome.iip.kit.edu/api/api/getAnreizeAuswertungKalender/",
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        this.setState({ anreizAuswertung: data });
      })
      .catch((error) => console.log(error));
    this.getPhases(this.state.date);
  }

  getPhases(date) {
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getAnreiz/${date.getDate()}-${
        date.getMonth() + 1
      }-${date.getFullYear()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data?.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].limit !== null &&
            data[0].mode !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
                reward_points: element.reward_points,
                storyline_multiplikator: element.storyline_multiplikator,
                storyline_name: element.storyline_name,
                geschafft: data[0].geschafft,
              });
              number = number + 1;
            }
          }
        }
        this.setState({ phases: result });
      })
      .catch((error) => console.log(error));
  }

  /* render() stellt abhängig vom State 4 verschieden Anzeigen dar.*/

  render() {
    if (this.state.anzeige === "min") {
      //Anzeige: Plot(min)
      return (
        <div className="parent">
          <Calendar
            tileClassName={({ date, view }) => {
              if (view === "month") {
                if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "true")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "green";
                } else if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "false")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "red";
                }
              }
            }}
            minDetail="year"
            next2Label={null}
            prev2Label={null}
            onChange={this.onChange}
            value={this.state.date}
          />
          <form className="tagForm" onChange={this.onChangeValue}>
            <input id="b1" type="radio" name="auswahl" value="sec" />
            <label className="labelClass" for="b1">
              {" "}
              Plot (sec.){" "}
            </label>
            <input
              id="b2"
              type="radio"
              name="auswahl"
              value="min"
              defaultChecked
            />
            <label className="labelClass" for="b2">
              {" "}
              Plot (min.){" "}
            </label>
            <input id="b3" type="radio" name="auswahl" value="bar" />
            <label className="labelClass" for="b3">
              {" "}
              Bar chart{" "}
            </label>
            <input id="b4" type="radio" name="auswahl" value="anreiz" />
            <label className="labelClass" for="b4">
              {" "}
              Incentive{" "}
            </label>
          </form>
          <select value={this.state.spalte} onChange={this.handleChange}>
            <option value="Leistung_Gesamt">Total power</option>
            <option value="2fach_Dose_Eingang">Socket entrance</option>
            <option value="2fach_Dose_Herd_rechts">
              Socket stove right
            </option>
            <option value="2fach_Dose_Kuechenfenster_links">
              Socket kitchen window left
            </option>
            <option value="2fach_Dose_Kuechenfenster_rechts">
              Socket kitchen window right
            </option>
            <option value="2fach_Dose_Tuer_Schlafen1">
              Socket left bedroom door
            </option>
            <option value="2fach_Dose_Tuer_Schlafen2">
              Socket right bedroom door
            </option>
            <option value="3fach_Dose_Bad">Socket bathroom</option>
            <option value="3fach_Dose_Kueche_links">
              Socket kitchen left
            </option>
            <option value="3fach_Dose_Kueche_rechts">
              Socket kitchen right
            </option>
            <option value="4fach_Dose_TV_1">4fach_Dose_TV_1</option>
            <option value="4fach_Dose_TV_2">4fach_Dose_TV_2</option>
            <option value="5fach_Dose_Schlafen1_1">
              Socket left bedroom 1
            </option>
            <option value="5fach_Dose_Schlafen1_2">
              Socket left bedroom 2
            </option>
            <option value="5fach_Dose_Schlafen2_1">
             Socket right bedroom 1
            </option>
            <option value="5fach_Dose_Schlafen2_2">
             Socket right bedroom 2
            </option>
            <option value="Backofen">Oven</option>
            <option value="Dunstabzugshaube">Extractor hood</option>
            <option value="Gefrierschrank">Freezer</option>
            <option value="Kaffeemaschine">Coffee machine</option>
            <option value="Klimaanlage">Air conditioning</option>
            <option value="Kochfeld1">Stove 1</option>
            <option value="Kochfeld2">Stove 2</option>
            <option value="Kochfeld3">Stove 3</option>
            <option value="Kuehlschrank">Refrigerator</option>
            {/* <option value='LEER1'>LEER1</option>
                  <option value='LEER2'>LEER2</option>
                  <option value='LEER3'>LEER3</option>
                  <option value='LEER4'>LEER4</option>
                  <option value='LEER5'>LEER5</option>
                  <option value='LEER6'>LEER6</option> */}
            <option value="Licht_Bad">Lights bathroom</option>
            <option value="Licht_Hauptraum">Lights living room</option>
            <option value="Licht_Schlafen1">Lights left bedroom</option>
            <option value="Licht_Schlafen2">Lights right bedroom</option>
            <option value="Spuelmaschine">Dishwasher</option>
            <option value="Trockner">Tumble dryer</option>
            <option value="Tuer_zu_Schlafen1">Socket living room 1</option>
            <option value="Tuer_zu_Schlafen2">Socket living room 2</option>
            <option value="Waschmaschine">Washing machine</option>
            <option value="PVGes">PV</option>
          </select>

          <div>
            {/* endtime wird auf den letzmöglichen Timestamp eines Tages gesetzt, oder auf den aktuellen Timestamp, falls der aktuelle Tag abgefragt wird.*/}
            <LinePlot
              spalte={this.state.spalte}
              starttime={this.state.date.setHours(0, 0, 0, 0)}
              endtime={
                new Date(this.state.date).setHours(0, 0, 0, 0) ===
                new Date().setHours(0, 0, 0, 0)
                  ? Date.now() * 1
                  : this.state.date.setHours(23, 59, 59, 999)
              }
              min="true"
            />
          </div>
        </div>
      );
    } else if (this.state.anzeige === "sec") {
      //Anzeige: Plot(sek)
      return (
        <div className="parent">
          <Calendar
            tileClassName={({ date, view }) => {
              if (view === "month") {
                if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "true")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "green";
                } else if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "false")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "red";
                }
              }
            }}
            next2Label={null}
            prev2Label={null}
            minDetail="year"
            onChange={this.onChange}
            value={this.state.date}
          />
          <form className="tagForm" onChange={this.onChangeValue}>
            <input
              id="b5"
              type="radio"
              name="auswahl"
              value="sec"
              defaultChecked
            />
            <label className="labelClass" for="b5">
              {" "}
              Plot (sec.){" "}
            </label>
            <input id="b6" type="radio" name="auswahl" value="min" />
            <label className="labelClass" for="b6">
              Plot (min.){" "}
            </label>
            <input id="b7" type="radio" name="auswahl" value="bar" />
            <label className="labelClass" for="b7">
              {" "}
              Bar chart{" "}
            </label>
            <input id="b8" type="radio" name="auswahl" value="anreiz" />
            <label className="labelClass" for="b8">
              {" "}
              Incentive{" "}
            </label>
          </form>
          <select value={this.state.spalte} onChange={this.handleChange}>
            <option value="Leistung_Gesamt">Total power</option>
            <option value="2fach_Dose_Eingang">Socket entrance</option>
            <option value="2fach_Dose_Herd_rechts">
              Socket stove right
            </option>
            <option value="2fach_Dose_Kuechenfenster_links">
              Socket kitchen window left
            </option>
            <option value="2fach_Dose_Kuechenfenster_rechts">
              Socket kitchen window right
            </option>
            <option value="2fach_Dose_Tuer_Schlafen1">
              Socket left bedroom door
            </option>
            <option value="2fach_Dose_Tuer_Schlafen2">
              Socket right bedroom door
            </option>
            <option value="3fach_Dose_Bad">Socket bathroom</option>
            <option value="3fach_Dose_Kueche_links">
              Socket kitchen left
            </option>
            <option value="3fach_Dose_Kueche_rechts">
              Socket kitchen right
            </option>
            <option value="4fach_Dose_TV_1">4fach_Dose_TV_1</option>
            <option value="4fach_Dose_TV_2">4fach_Dose_TV_2</option>
            <option value="5fach_Dose_Schlafen1_1">
              Socket left bedroom 1
            </option>
            <option value="5fach_Dose_Schlafen1_2">
              Socket left bedroom 2
            </option>
            <option value="5fach_Dose_Schlafen2_1">
             Socket right bedroom 1
            </option>
            <option value="5fach_Dose_Schlafen2_2">
             Socket right bedroom 2
            </option>
            <option value="Backofen">Oven</option>
            <option value="Dunstabzugshaube">Extractor hood</option>
            <option value="Gefrierschrank">Freezer</option>
            <option value="Kaffeemaschine">Coffee machine</option>
            <option value="Klimaanlage">Air conditioning</option>
            <option value="Kochfeld1">Stove 1</option>
            <option value="Kochfeld2">Stove 2</option>
            <option value="Kochfeld3">Stove 3</option>
            <option value="Kuehlschrank">Refrigerator</option>
            {/* <option value='LEER1'>LEER1</option>
                  <option value='LEER2'>LEER2</option>
                  <option value='LEER3'>LEER3</option>
                  <option value='LEER4'>LEER4</option>
                  <option value='LEER5'>LEER5</option>
                  <option value='LEER6'>LEER6</option> */}
            <option value="Licht_Bad">Lights bathroom</option>
            <option value="Licht_Hauptraum">Lights living room</option>
            <option value="Licht_Schlafen1">Lights left bedroom</option>
            <option value="Licht_Schlafen2">Lights right bedroom</option>
            <option value="Spuelmaschine">Dishwasher</option>
            <option value="Trockner">Tumble dryer</option>
            <option value="Tuer_zu_Schlafen1">Socket living room 1</option>
            <option value="Tuer_zu_Schlafen2">Socket living room 2</option>
            <option value="Waschmaschine">Washing machine</option>
            <option value="PVGes">PV</option>
          </select>
          <div>
            {/* endtime wird auf den letzmöglichen Timestamp eines Tages gesetzt, oder auf den aktuellen Timestamp, falls der aktuelle Tag abgefragt wird.*/}
            <LinePlot
              spalte={this.state.spalte}
              starttime={this.state.date.setHours(0, 0, 0, 0)}
              endtime={
                new Date(this.state.date).setHours(0, 0, 0, 0) ===
                new Date().setHours(0, 0, 0, 0)
                  ? Date.now() * 1
                  : this.state.date.setHours(23, 59, 59, 999)
              }
              min="false"
            />
          </div>
        </div>
      );
    } else if (this.state.anzeige === "bar") {
      //Anzeige: Barplot
      return (
        <div className="parent">
          <Calendar
            tileClassName={({ date, view }) => {
              if (view === "month") {
                if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "true")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "green";
                } else if (
                  this.state.anreizAuswertung
                    .filter((element) => element.geschafft === "false")
                    .map((element) => new Date(element.date) * 1)
                    .includes(date * 1)
                ) {
                  return "red";
                }
              }
            }}
            next2Label={null}
            prev2Label={null}
            minDetail="year"
            onChange={this.onChange}
            value={this.state.date}
          />
          <form className="tagForm" onChange={this.onChangeValue}>
            <input id="b9" type="radio" name="auswahl" value="sec" />
            <label className="labelClass" for="b9">
              {" "}
              Plot (sec.){" "}
            </label>
            <input id="b10" type="radio" name="auswahl" value="min" />
            <label className="labelClass" for="b10">
              {" "}
              Plot (min.){" "}
            </label>
            <input
              id="b11"
              type="radio"
              name="auswahl"
              value="bar"
              defaultChecked
            />
            <label className="labelClass" for="b11">
              {" "}
              Bar chart{" "}
            </label>
            <input id="b12" type="radio" name="auswahl" value="anreiz" />
            <label className="labelClass" for="b12">
              {" "}
              Incentive{" "}
            </label>
          </form>
          <div>
            <BarPlot
              starttime={this.state.date.setHours(0, 0, 0, 0)}
              endtime={this.state.date.setHours(23, 59, 59, 999)}
            />
          </div>
        </div>
      );
    } else if (this.state.anzeige === "anreiz") {
      //Anzeige: Anreiz Bild
      if (this.state.date.setHours(0, 0, 0, 0) <= new Date()) {
        // Anreiz Bilder werden nicht für die Zukunft abgefragt
        return (
          <div className="parent">
            <Calendar
              tileClassName={({ date, view }) => {
                if (view === "month") {
                  if (
                    this.state.anreizAuswertung
                      .filter((element) => element.geschafft === "true")
                      .map((element) => new Date(element.date) * 1)
                      .includes(date * 1)
                  ) {
                    return "green";
                  } else if (
                    this.state.anreizAuswertung
                      .filter((element) => element.geschafft === "false")
                      .map((element) => new Date(element.date) * 1)
                      .includes(date * 1)
                  ) {
                    return "red";
                  }
                }
              }}
              next2Label={null}
              prev2Label={null}
              minDetail="year"
              onChange={this.onChange}
              value={this.state.date}
            />
            <form className="tagForm" onChange={this.onChangeValue}>
              <input id="b13" type="radio" name="auswahl" value="sec" />
              <label className="labelClass" for="b13">
                {" "}
                Plot (sek.){" "}
              </label>
              <input id="b14" type="radio" name="auswahl" value="min" />
              <label className="labelClass" for="b14">
                {" "}
                Plot (min.){" "}
              </label>
              <input id="b15" type="radio" name="auswahl" value="bar" />
              <label className="labelClass" for="b15">
                {" "}
                Balkendiagramm{" "}
              </label>
              <input
                id="b16"
                type="radio"
                name="auswahl"
                value="anreiz"
                defaultChecked
              />
              <label className="labelClass" for="b16">
                {" "}
                Anreiz{" "}
              </label>
            </form>
            {this.state.phases.length > 0 ? (
              <div style={{ width: "100%", overflowX: "auto" }}>
                <table
                  style={{
                    textAlign: "center",
                    fontSize: "150%",
                    borderCollapse: "separate",
                    borderSpacing: "25px 0",
                    margin: "0 auto",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Time period</th>
                      <th>Type</th>
                      <th>Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.phases.map((phase) => (
                      <tr>
                        <td>{`${new Date(phase.start).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })} - ${new Date(phase.end).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}`}</td>
                        <td>
                          {phase.mode === "max" ? "Maximum" : "Average"}
                        </td>
                        <td>{phase.limit}</td>
                      </tr>
                    ))}
                    {this.state.phases[0].storyline_name !== "" ? (
                      <tr>
                        <th>Points</th>
                        <th>Multiplier</th>
                        <th>Storyline</th>
                      </tr>
                    ) : (
                      <tr>
                        <th></th>
                        <th>Points</th>
                        <th></th>
                      </tr>
                    )}
                    {this.state.phases[0].storyline_name !== "" ? (
                      <tr>
                        <td>{this.state.phases[0].reward_points}</td>
                        <td>{this.state.phases[0].storyline_multiplikator}</td>
                        <td>{this.state.phases[0].storyline_name}</td>
                      </tr>
                    ) : (
                      <tr>
                        <td></td>
                        <td>{this.state.phases[0].reward_points}</td>
                        <td></td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              ""
            )}
            <img
              style={{ width: "60%" }}
              src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${this.state.date.getDate()}-${
                this.state.date.getMonth() + 1
              }-${this.state.date.getFullYear()}/anreiz/?ts=${Date.now()}`}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = keinAnreiz;
              }}
              onClick={this.handleOpen}
              alt="Incentives could not be found."
            />
            <Modal
              open={this.state.modalOpen}
              onClose={this.handleClose}
              size={window.innerWidth < 1080 ? "" : "full"}
            >
              <img
                style={{ width: "100%" }}
                src={`https://smarthome.iip.kit.edu/api/api/getAnreizBilder/${this.state.date.getDate()}-${
                  this.state.date.getMonth() + 1
                }-${this.state.date.getFullYear()}/anreiz/?ts=${Date.now()}`}
                onError={(e) => {
                  e.target.onError = null;
                  e.target.src = keinAnreiz;
                }}
                onClick={this.handleClose}
                alt="Incentives could not be found."
              />
            </Modal>
          </div>
        );
      } else {
        //Wenn das ausgeählte Datum in der Zukunft liegt.
        return (
          <div className="parent">
            <Calendar
              tileClassName={({ date, view }) => {
                if (view === "month") {
                  if (
                    this.state.anreizAuswertung
                      .filter((element) => element.geschafft === "true")
                      .map((element) => new Date(element.date) * 1)
                      .includes(date * 1)
                  ) {
                    return "green";
                  } else if (
                    this.state.anreizAuswertung
                      .filter((element) => element.geschafft === "false")
                      .map((element) => new Date(element.date) * 1)
                      .includes(date * 1)
                  ) {
                    return "red";
                  }
                }
              }}
              next2Label={null}
              prev2Label={null}
              minDetail="year"
              onChange={this.onChange}
              value={this.state.date}
            />
            <form className="tagForm" onChange={this.onChangeValue}>
              <input id="b21" type="radio" name="auswahl" value="sec" />
              <label className="labelClass" for="b21">
                {" "}
                Plot (sec.){" "}
              </label>
              <input id="b22" type="radio" name="auswahl" value="min" />
              <label className="labelClass" for="b22">
                {" "}
                Plot (min.){" "}
              </label>
              <input id="b23" type="radio" name="auswahl" value="bar" />
              <label className="labelClass" for="b23">
                {" "}
                Bar chart{" "}
              </label>
              <input
                id="b24"
                type="radio"
                name="auswahl"
                value="anreiz"
                defaultChecked
              />
              <label className="labelClass" for="b24">
                {" "}
                Incentive{" "}
              </label>
            </form>
            <div className="historischeKennzahlen">
              Nobody can look into the future :)
            </div>
          </div>
        );
      }
    }
  }
}

export default Tag;
