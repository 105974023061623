import React, {useEffect, useState} from 'react';
import { Button, DateRangePicker, InputNumber, SelectPicker } from 'rsuite';
import { startOfDay, endOfDay, format } from 'date-fns';

export default function Budgets(props) {

    const [budgetValues, setBudgetValues] = useState([])

    useEffect(() => {
        setBudgetValues(props.budgetValues)
    }, [props.budgetValues])

    function addBudget() {
        setBudgetValues(
            [...budgetValues, 
            {
                number: budgetValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.date))) * 1,
                end: endOfDay(new Date(Date.parse(props.date))) * 1,
                modus: 'max',
                budget: 0,
                start_date: format(new Date(Date.parse(props.date)), "dd-MM-yyyy"),
                end_date: format(new Date(Date.parse(props.date)), "dd-MM-yyyy")
            }]
        )
        props.onChange(
            [...budgetValues,
            {
                number: budgetValues.length + 1,
                start: startOfDay(new Date(Date.parse(props.date))) * 1,
                end: endOfDay(new Date(Date.parse(props.date))) * 1,
                modus: 'max',
                budget: 0,
                start_date: format(new Date(Date.parse(props.date)), "yyyy-MM-dd"),
                end_date: format(new Date(Date.parse(props.date)), "yyyy-MM-dd")
            }]
        )
    }

    function deleteBudget() {
        setBudgetValues([...budgetValues].slice(0, -1))
        props.onChange([...budgetValues].slice(0, -1))
    }

    function drawBudgets() {
        const budgetComponents = []
        if (budgetValues?.length > 0) {
            for (const budgetValue of budgetValues) {
                budgetComponents.push(
                    <div style={{display: 'flex', flexDirection: 'column', marginBottom: '2%'}} key={budgetValue.number}>
                        <div style={{display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                            <span>
                                Budget {budgetValue.number}
                            </span>
                            <DateRangePicker
                                format="yyyy-MM-dd"
                                placement="auto"
                                onOk={value => {
                                    const found = budgetValues?.find(test => test.number === budgetValue.number) 
                                    found.start = value[0] * 1
                                    found.end = value[1] * 1
                                    found.start_date = format(value[0], "yyyy-MM-dd")
                                    found.end_date = format(value[1], "yyyy-MM-dd")
                                    setBudgetValues([...budgetValues])
                                    props.onChange([...budgetValues])
                                }}
                                ranges={[{
                                    value: [new Date(Date.parse(props.date)), new Date(Date.parse(props.date))]
                                }]}
                                value={[new Date(budgetValue.start), new Date(budgetValue.end)]}
                                cleanable={false}
                            />
                            <div style={{width: '130px'}}>
                                <InputNumber
                                    postfix="kg CO2"
                                    defaultValue={0}
                                    step={10}
                                    min={0}
                                    onChange={value => {
                                        const found = budgetValues?.find(test => test.number === budgetValue.number) 
                                        found.budget = value
                                        setBudgetValues([...budgetValues])
                                        props.onChange([...budgetValues])
                                    }}
                                    value={budgetValue.budget}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        }
        
        return budgetComponents
    }

    return(
        <>
            <div style={{width: '100%', display: 'flex', justifyContent: 'space-evenly', marginBottom: '2%'}}>
                <Button
                    onClick={addBudget}
                    style={{width: '10%'}}
                    appearance="primary"
                >
                    +
                </Button>
                <Button
                    onClick={deleteBudget}
                    disabled={budgetValues?.length === 0}
                    style={{width: '10%'}}
                    appearance="primary"
                >
                    -
                </Button>
            </div>
            <div>
                {drawBudgets()}   
            </div>
        </>
    )
}