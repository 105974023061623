import React, { Component } from "react";
import Plot from "react-plotly.js";

class BarPlotLive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsondatay: null,
      jsondatax: null,
      loading: true,
      datamissing: false,
      chachedWidth: window.outerWidth,
    };
  }

  handleResize = (e) => {
    if (this.state.chachedWidth !== window.outerWidth) {
      window.location.reload(false); //deprecated; Der Browser wird zu einem reload gezwungen, da sich der Plot ansonsten nicht immer wieder an die Größe des viewports anpasst.
    }
    this.setState({ chachedWidth: window.outerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize); // Immer wenn die Größe des viewports geändert wird, wird handleResize() aufgerufen
    this.fetchData();
    this.myinterval = setInterval(() => {
      this.fetchData();
    }, 10000);
  }

  componentDidUpdate(prevProps) {
    if (this.props.starttime !== prevProps.starttime) {
      this.fetchData();
    }
  }

  /* fetchData() fragt den prozentualen Anteil am Verbrauch des ESHL ab. Informationen zum Rest stehen in der API. */

  fetchData() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      "https://smarthome.iip.kit.edu/api/api/getbarchartpercentagelive/",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          this.setState({
            datamissing: false,
          });
          return response.json().then((responsedata) =>
            this.setState({
              jsondatay: responsedata.myData.data[0],
              jsondatax: Object.values(responsedata.myData.columns),
              loading: false,
            })
          );
        } else if (response.status === 404) {
          this.setState({
            datamissing: true,
          });
          return Promise.reject("error 404");
        } else {
          this.setState({
            datamissing: true,
          });
          return Promise.reject("some other error: " + response.status);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    clearInterval(this.myinterval);
  }

  render() {
    if (this.state.datamissing === true) {
      return (
        <div>
          <p>Error: Data not found</p>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: window.innerWidth > 1081 ? "1080px" : window.innerWidth,
          }}
        >
          <Plot
            data={[
              {
                type: "bar",
                x: this.state.jsondatax,
                y: this.state.jsondatay,
              },
            ]}
            layout={{
              xaxis: {},
              yaxis: {
                title: "Percentage share of total demand",
                categoryorder: "total descending",
              },
              autosize: true,
              plot_bgcolor: "rgba(0, 0, 0, 0)",
              paper_bgcolor: "rgba(0, 0, 0, 0)",
            }}
            style={{ width: "100%" }}
            useResizeHandler
          />
        </div>
      );
    }
  }
}

export default BarPlotLive;
