// CO2Budget.js
import React from 'react';
import BudgetPlot from '../components/Plot/BudgetPlot';

class CO2Budget extends React.Component {
  render() {
    return (
      <div>
        <BudgetPlot />
      </div>
    );
  }
}

export default CO2Budget;