import React, { Component } from "react";
import Plot from "react-plotly.js";

class LinePlotLive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsondatay: null,
      jsondatax: null,
      loading: true,
      minstring: props.min,
      datamissing: false,
      chachedWidth: window.outerWidth,
      phases: [],
      plotData: [],
    };
  }

  handleResize = (e) => {
    if (this.state.chachedWidth !== window.outerWidth) {
      window.location.reload(false); //deprecated; Der Browser wird zu einem reload gezwungen, da sich der Plot ansonsten nicht immer wieder an die Größe des viewports anpasst.
    }
    this.setState({ chachedWidth: window.outerWidth });
  };

  componentDidMount() {
    window.addEventListener("resize", this.handleResize); // Immer wenn die Größe des viewports geändert wird, wird handleResize() aufgerufen
    this.getPhases();
    this.fetchData();
    this.myinterval = setInterval(() => {
      this.fetchData();
    }, 3000); // Der Liveplot wird alle 3 Sekunden aktualisiert
  }

  componentDidUpdate(prevProps) {
    if (this.props.spalte !== prevProps.spalte) {
      // Wenn man die gleiche Spalte nochmal auswählt ändert sich nichts.
      this.setState({
        loading: true,
      });
    }
  }

  getPhases() {
    fetch(
      `https://smarthome.iip.kit.edu/api/api/getAnreiz/${new Date().getDate()}-${
        new Date().getMonth() + 1
      }-${new Date().getFullYear()}/`,
      {
        method: "GET",
        credentials: "include",
      }
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response.status);
        }
      })
      .then((data) => {
        let result = [];
        if (data?.length > 0) {
          if (
            data[0].start_timestamp !== null &&
            data[0].end_timestamp !== null &&
            data[0].limit !== null &&
            data[0].mode !== null
          ) {
            let number = 1;
            for (const element of data) {
              result.push({
                number: number,
                start: element.start_timestamp,
                end: element.end_timestamp,
                limit: element.limit,
                mode: element.mode,
              });
              number = number + 1;
            }
          }
        }
        this.setState({ phases: result });
      })
      .catch((error) => console.log(error));
  }

  /* fetchData() fragt die Gesamtleistungsdaten des ESHL in sekündlichen Intervallen ab. */

  fetchData() {
    const requestOptions = {
      method: "GET",
      credentials: "include",
    };
    fetch(
      "https://smarthome.iip.kit.edu/api/api/getdata/" +
        this.props.spalte +
        "/" +
        (new Date() * 1 - 300000) +
        "/" +
        new Date() * 1 +
        "/min=false/",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          this.setState({
            datamissing: false,
          });
          return response.json();
        } else if (response.status === 404) {
          this.setState({
            datamissing: true,
          });
          return Promise.reject("error 404");
        } else {
          this.setState({
            datamissing: true,
          });
          return Promise.reject("some other error: " + response.status);
        }
      })
      .then((data) =>
        this.setState({
          jsondatay: Object.values(data.myData[this.props.spalte]),
          jsondatax: Object.values(data.myData.Timestamp),
          loading: false,
        })
      )
      .then(() => {
        this.getPlotData();
      })
      .catch((error) => {
        console.log("404 no data");
      });
  }

  componentWillUnmount() {
    clearInterval(this.myinterval);
  }

  /* toDate(array) wandelt ein Array von Timestamps zu Daten um. */

  toDate(array) {
    const dateArray = [];
    for (let i = 0; i < array.length; i++) {
      dateArray.push(new Date(array[i]));
    }
    return dateArray;
  }

  getPlotData() {
    const plotData = [];
    let phaseDataX = [];
    let phaseDataY = [];
    for (const phase of this.state.phases) {
      phaseDataX = this.state.jsondatax.filter(
        (number) => number >= phase.start && number <= phase.end
      );
      phaseDataY = Array.from(
        { length: phaseDataX.length },
        (v, i) => phase.limit
      );
      plotData.push({
        type: "line",
        x: this.toDate(phaseDataX),
        y: phaseDataY,
        line: {
          color: "red",
          dash: phase.mode === "max" ? "solid" : "dash",
        },
        name: phase.mode === "max" ? "Maximum" : "Average",
      });
    }
    plotData.push({
      type: "line",
      x: this.toDate(this.state.jsondatax),
      y: this.state.jsondatay,
      line: {
        color: "blue",
      },
      name: "Demand",
    });
    this.setState({
      plotData: plotData,
    });
  }

  render() {
    if (this.state.datamissing === true) {
      return (
        <div>
          <p>Error: Data not found</p>
        </div>
      );
    } else if (this.state.loading === true) {
      return (
        <div>
          <p>Loading...</p>
        </div>
      );
    } else {
      return (
        <div
          style={{
            width: window.innerWidth > 1081 ? "1080px" : window.innerWidth,
          }}
        >
          <Plot
            data={this.state.plotData}
            layout={{
              xaxis: {
                title: "Time",
              },
              yaxis: {
                title: "Power demand in Watt",
                rangemode: "tozero",
              },
              autosize: true,
              plot_bgcolor: "rgba(0, 0, 0, 0)",
              paper_bgcolor: "rgba(0, 0, 0, 0)",
            }}
            style={{ width: "100%" }}
            useResizeHandler
          />
        </div>
      );
    }
  }
}

export default LinePlotLive;
