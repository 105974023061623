import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";

export default function EarthDay(props) {
  const [consumed, setConsumed] = useState(0);
  const [earthsConsumed, setEarthsConsumed] = useState(0);

  useEffect(() => {
    var d = new Date();
    d.setDate(d.getDate() - 14);

    const requestOptions = {
      method: "GET",
      credentials: "include",
    };

    fetch(
      "https://smarthome.iip.kit.edu/api/api/getmaxandmean/Leistung_Gesamt/" +
        (props.start === "" ? d * 1 : props.start) +
        "/" +
        Date.now() +
        "/",
      requestOptions
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
      })
      .then((data) => setConsumed(data.kwh))
      .catch((error) => {
        console.log(error);
      });
  }, [props]);

  useEffect(() => {
    const deutscher_durchschnitt = 2000 / 365.25;
    const eshl_durchschnitt =
      consumed /
      Math.ceil(
        (Date.now() -
          (props.start === ""
            ? new Date().setDate(new Date().getDate() - 14) * 1
            : props.start)) /
          (1000 * 3600 * 24)
      );
    setEarthsConsumed(
      // Earth Overshoot Day 2022 in Deutschland am 04.05. => 2.9435483871 Erden im Jahr
      // 2.9435483871 * (eshl_durchschnitt / deutscher_durchschnitt)
      (eshl_durchschnitt / deutscher_durchschnitt)
    );
  }, [consumed, props.start]);

  return (
    <div style={{ width: "60%", marginBottom: "2%" }}>
      <p style={{ textAlign: "center" }}>
        You consume as much as {Math.round(earthsConsumed * 100) / 100} average two-person households.
      </p>
      <ProgressBar>
        <ProgressBar
          max={4}
          variant="success"
          now={earthsConsumed > 1 ? 1 : earthsConsumed}
        ></ProgressBar>
        <ProgressBar
          max={4}
          variant="danger"
          now={earthsConsumed > 1 ? earthsConsumed - 1 : 0}
        ></ProgressBar>
      </ProgressBar>
    </div>
  );
}
